import { isProdEnv } from 'shared/config';

export class Logger {
  constructor(private _tag: string, private _isActive = true, private _runOnProduction = false) {}

  log(message: string, ...args: any[]) {
    if (this._isActive && isProdEnv === this._runOnProduction)
      // eslint-disable-next-line no-console
      console.log(`[${this._tag}]: ${message}`, ...args);
  }
}
