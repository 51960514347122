import { PageName } from 'shared/lib/types/app/pages';
import type { RouteRecordRaw, Router } from 'vue-router';

export const moduleRoute: RouteRecordRaw = {
  path: 'tasks',
  name: PageName.BASE_HOME,
  redirect: { name: PageName.TASKS_PAGE },
  meta: {
    breadcrumbName: 'Задания',
  },
  component: () => import('shared/ui/TheEmptyRouterView.vue'),
  children: [
    {
      path: '',
      name: PageName.TASKS_PAGE,
      component: () => import('./pages/TaskIndex.vue'),
    },
  ],
};

export default (router: Router) => {
  router.addRoute(PageName.BASE_LAYOUT, moduleRoute);
};
