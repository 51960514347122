import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { IErrorData, LoadingService } from 'shared/lib/types/app/app';
import { useToast } from 'mechtaui';
import { useAuthService } from 'modules/auth/model/auth.service';
import { useAuthStore } from 'shared/stores/auth.store';
import { useGlobalLoading } from '../lib/composables/useLoading';
import { getErrorMessage } from './error-handler';
import { http } from './http';

export enum LoadingType {
  Global = 'global',
}

export interface ExecutorOptions<T> {
  request: Promise<AxiosResponse<T>>
  loadingState?: LoadingService
  loadingType?: LoadingType
  ignoreError?: boolean
  detailedResponse?: boolean
  onResult?: (data: T) => void
  onError?: (error: any) => void
  onComplete?: () => void
}

const globalLoadingState = useGlobalLoading();

export abstract class Executor {
  static async run<T = any>(options: ExecutorOptions<T>): Promise<AxiosResponse<T>> {
    const {
      request,
      onResult,
      onError,
      onComplete,
      ignoreError,
    } = options;
    let { loadingState } = options;

    if (!loadingState)
      loadingState = globalLoadingState;

    const loading = (loading: boolean) => {
      if (loadingState)
        loading ? loadingState.startLoading() : loadingState.stopLoading();
    };

    const handleError = async (error: AxiosError<IErrorData>) => {
      if (!ignoreError && !(error.response && error.response?.status === 401)) {
        const { toast } = useToast();

        toast({
          title: `Ошибка ${error.response?.status}`,
          description: getErrorMessage(error.response?.data.code ?? ''),
          duration: 3000,
          variant: 'destructive',
        });
      }

      // If access token is expired, try to refresh it with refresh token
      if (error.response && error.response?.status === 401) {
        const { authByToken } = useAuthService();
        const authStore = useAuthStore();

        authStore.token = null;

        if (authStore.refreshToken) {
          await authByToken({
            refresh_token: authStore.refreshToken,
          });

          // Re-call request with fresh access token
          try {
            const result = await http.request(error.config as AxiosRequestConfig) as AxiosResponse<T>;

            onResult?.(result.data);

            return result;
          } catch (error) {
          // If still gets error, logout, because refresh token is expired too
            if ((error as AxiosError<IErrorData>).response?.status === 401)
              authStore.logout();
          }
        }
      }

      onError?.(error);
    };

    try {
      loading(true);

      const result = await request as AxiosResponse<T>;

      onResult?.(result.data);

      return result;
    } catch (error) {
      await handleError(error as AxiosError<IErrorData>);

      return error as AxiosResponse<T>;
    } finally {
      loading(false);
      onComplete?.();
    }
  }
}
