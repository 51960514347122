import { PageName } from 'shared/lib/types/app/pages';
import type { RouteRecordRaw, Router } from 'vue-router';

export const moduleRoute: RouteRecordRaw = {
  path: 'users',
  component: () => import('shared/ui/TheEmptyRouterView.vue'),
  meta: {
    permissions: ['admin'],
  },
  children: [
    {
      path: '',
      name: PageName.USERS_PAGE,
      component: () => import('./pages/UsersMain.vue'),
      meta: {
        breadcrumbName: 'Пользователи',
      },
    },
    {
      path: 'add',
      name: 'UserAdd',
      component: () => import('./pages/UsersAdd.vue'),
      meta: {
        breadcrumbName: 'Добавление пользователя',
      },
    },
    {
      path: ':userId',
      name: 'UserView',
      component: () => import('./pages/UsersView.vue'),
      meta: {
        breadcrumbName: 'Просмотр пользователя',
      },
    },
    {
      path: ':userId/edit',
      name: 'UserEdit',
      component: () => import('./pages/UsersEdit.vue'),
      meta: {
        breadcrumbName: 'Редактирование пользователя',
      },
    },
  ],
};

export default (router: Router) => {
  router.addRoute(PageName.BASE_LAYOUT, moduleRoute);
};
