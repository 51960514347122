import { PageName } from 'shared/lib/types/app/pages';
import type { RouteRecordRaw, Router } from 'vue-router';

export const moduleRoute: RouteRecordRaw = {
  path: '/auth',
  name: PageName.AUTH_LAYOUT,
  component: () => import('shared/ui/layouts/TheLayoutAuth.vue'),
  children: [
    {
      path: 'login',
      name: PageName.LOGIN_PAGE,
      component: () => import('./pages/AuthLogin.vue'),
    },
  ],
};

export default (router: Router) => {
  router.addRoute(moduleRoute);
};
