/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup';

const ru = {
  mixed: {
    default: 'Недопустимое значение',
    required: 'Это обязательное поле',
    oneOf: 'Должно быть одним из следующих значений: ${values}',
    notOneOf: 'Не должно быть одним из следующих значений: ${values}',
  },
  string: {
    length: 'Должно быть ровно ${length} символов',
    min: 'Должно быть хотя бы ${min} символов',
    max: 'Должно быть не более ${max} символов',
    matches: 'Должно соответствовать шаблону: "${regex}"',
    email: 'Должно быть действительным электронным адресом',
    url: 'Должно быть действительным URL',
    trim: 'Не должно иметь пробелов в начале или в конце',
    lowercase: 'Должно быть в нижнем регистре',
    uppercase: 'Должно быть в верхнем регистре',
  },
  number: {
    min: 'Должно быть не менее ${min}',
    max: 'Должно быть не более ${max}',
    lessThan: 'Должно быть меньше ${less}',
    moreThan: 'Должно быть больше ${more}',
    positive: 'Должно быть положительным числом',
    negative: 'Должно быть отрицательным числом',
    integer: 'Должно быть целым числом',
  },
  date: {
    min: 'Должно быть после ${min}',
    max: 'Должно быть до ${max}',
  },
  object: {
    noUnknown: 'Не может содержать ключи, которые не определены в схеме',
  },
  array: {
    min: 'Должно содержать хотя бы ${min} элементов',
    max: 'Должно содержать не более ${max} элементов',
  },
};

export function setLocaleYUP() {
  return setLocale(ru);
}
