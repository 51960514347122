type Err = string;

const errorDictionary: { [key in Err]: string } = {
  err_no_rows: 'Нет строк',
  service_not_available: 'Сервис недоступен',
  not_authorized: 'Не авторизован',
  object_not_found: 'Объект не найден',
  incorrect_page_size: 'Некорректный размер страницы',
  bad_status_code: 'Неверный код статуса',
  invalid_id: 'Недействительный идентификатор',
  send_limit_exceeded: 'Превышен лимит отправки',
  security_code_not_valid: 'Недействительный код безопасности',
  security_code_has_not_sent: 'Код безопасности не отправлен',
  frequent_requests: 'Частые запросы',
  order_blocked: 'Заказ заблокирован',
  already_verified: 'Уже подтверждено',
  verification_denied: 'Верификация отклонена',
  route_id_required: 'Требуется идентификатор маршрута',
  order_id_required: 'Требуется идентификатор заказа',
  invalid_phone: 'Недействительный номер телефона',
  empty_data: 'Пустые данные',
  checkout_not_allowed: 'Оформление заказа не разрешено',
  phone_already_exist: 'Номер телефона уже существует',
  // user errors
  user_name_required: 'Требуется имя пользователя',
  user_id_required: 'Требуется идентификатор пользователя',
  user_phone_required: 'Требуется телефон пользователя',
  bad_user_phone: 'Неверный телефон пользователя',
  user_deactivated: 'Пользователь деактивирован',
  // role errors
  role_name_required: 'Требуется название роли',
  role_id_required: 'Требуется идентификатор роли',
};

// Function to get the Russian error message
function getErrorMessage(errorCode: Err): string {
  return errorDictionary[errorCode] || 'Неизвестная ошибка';
}

export { errorDictionary, getErrorMessage };
