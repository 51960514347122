import { http } from 'shared/api/http';

export class SharedUserRepository {
  static async readProfile() {
    const response = await http.request({
      method: 'GET',
      url: '/user/profile',
    });

    return response;
  }
}
