import { useLoading } from 'shared/lib/composables/useLoading';
import { Executor } from 'shared/api/executor';
import { useAuthStore } from 'shared/stores/auth.store';
import { PageName } from 'shared/lib/types/app/pages';
import { AuthRepository } from '../api/auth.repository';
import type { IAuthByTokenDTO, IAuthDTO, IAuthSendCodeDTO, IAuthTokens } from './auth.dto';

export function useAuthService() {
  const loadingState = useLoading();
  const router = useRouter();
  const { setTokens } = useAuthStore();

  const isSmsSent = ref();
  const nextSendAfter = ref(0);

  async function sendVerificationCode(data: IAuthSendCodeDTO) {
    return Executor.run<{
      next_send_after_sec: number
    }>({
      request: AuthRepository.sendVerificationCode(data),
      loadingState,
      onResult(data) {
        isSmsSent.value = true;
        nextSendAfter.value = data.next_send_after_sec;
      },
      onError() {
        isSmsSent.value = false;
      },
    });
  }

  async function authByPhone(data: IAuthDTO) {
    return Executor.run<IAuthTokens>({
      request: AuthRepository.authByPhone(data),
      loadingState,
      onResult(data) {
        setTokens(data.access_token, data.refresh_token);

        router.push({
          name: PageName.BASE_HOME,
        });
      },
    });
  }

  async function authByToken(data: IAuthByTokenDTO) {
    return Executor.run<IAuthTokens>({
      request: AuthRepository.authByToken(data),
      loadingState,
      onResult(data) {
        setTokens(data.access_token, data.refresh_token);
      },
    });
  }

  return {
    isLoading: loadingState.isLoading,
    isSmsSent,
    nextSendAfter,
    sendVerificationCode,
    authByPhone,
    authByToken,
  };
}
