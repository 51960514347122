import { defineStore } from 'pinia';
import { store } from 'app/providers/store';
import type { IUserDTO } from 'shared/model/base.dto';

const STORE_ID = 'shared_user';

export const useSharedUserStore = defineStore(STORE_ID, () => {
  const state = reactive({
    user: null as IUserDTO | null,
    userDeliveryService: '',
  });

  watch(() => state.user?.service_ids, (val) => {
    if (val?.length)
      state.userDeliveryService = val[0];
  });

  return { ...toRefs(state) };
});

// Need to be used outside the setup
export function useSharedUserStoreOutside() {
  return useSharedUserStore(store);
}
