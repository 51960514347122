import { http } from 'shared/api/http';
import type { IAuthByTokenDTO, IAuthDTO, IAuthSendCodeDTO } from '../model/auth.dto';

// Naming convention for requests (CRUD):
// create'entity_name' - Post request with adding new data
// read'entity_name' -   Get request to get existing data (Not list of entities)
// update'entity_name' - Put/Post request to update existing data
// delete'entity_name' - Delete request to delete existing data
// fetch'list_name' -    Get request to get existing data list

export class AuthRepository {
  static async sendVerificationCode(data: IAuthSendCodeDTO) {
    const response = await http.request({
      method: 'POST',
      url: '/user/send_verification_code',
      data,
    });

    return response;
  }

  static async authByPhone(data: IAuthDTO) {
    const response = await http.request({
      method: 'POST',
      url: '/user/auth',
      data,
    });

    return response;
  }

  static async authByToken(data: IAuthByTokenDTO) {
    const response = await http.request({
      method: 'POST',
      url: '/user/auth_by_token',
      headers: {
        Authorization: data.refresh_token,
      },
    });

    return response;
  }
}
