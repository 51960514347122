import type { RouteRecordRaw, Router } from 'vue-router';

export const moduleRoutes: RouteRecordRaw[] = [
  {
    path: '/forbidden',
    name: 'Error403',
    meta: {
      title: '403',
    },
    component: () => import('modules/basic/pages/Error403.vue'),
  },
  {
    path: '/server-error',
    name: 'Error500',
    meta: {
      title: '500',
    },
    component: () => import('modules/basic/pages/Error500.vue'),
  },
  {
    // Important! Slash prefix ensures that route will be root and child of ErrorPage in same time
    path: '/:pathMatch(.*)*',
    name: 'Error404',
    meta: {
      title: '404',
    },
    component: () => import('modules/basic/pages/Error404.vue'),
  },
];

export default (router: Router) => {
  moduleRoutes.forEach(route => router.addRoute(route));
};
